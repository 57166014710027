@import './shared/styles/_vuetify-overrides.scss';

































































































































































































































.adjusted-dropdown {
	position: relative;
	top: 5px;
}

